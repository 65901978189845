import React from 'react'
import Layout from '../components/Layout'
import PropTypes from 'prop-types'
import Testimonials from '../Testimonials'

class TestimonialsPage extends React.Component {
  state = {
    menuVisible: false,
  }

  toggleMenu = () => {
    this.setState({ menuVisible: !this.state.menuVisible })
  }

  render() {
    return (
      <Layout menuVisible={this.state.menuVisible} toggleMenu={this.toggleMenu}>
        <Testimonials />
      </Layout>
    )
  }
}

export default TestimonialsPage
