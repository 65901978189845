import kerry from './img/testimonials/kerry.jpg'
import sam from './img/testimonials/sam.jpg'
import tim from './img/testimonials/tim.jpg'
import jerome from './img/testimonials/jerome.jpg'
import tomasz from './img/testimonials/tomasz.png'
import React from 'react'

export const Testimonials = () => (
  <section className="section-testimonials slider-holder" id={'testimonials'}>
    <div className="container">
      <div className="row">
        <div className="col-12">
          <h1 className="h1 section-title blue">
            <span className=" start">Testimonials</span>
          </h1>
        </div>
      </div>
      <div className="row">
        <div className="item">
          <div className=" d-flex justify-content-start align-items-center">
            <div className="testimonial-image">
              <img src={kerry} alt="Kerry Bollman" />
            </div>
            <div className="dark-holder">
              <label className="section-title white">Kerry Bollman</label>
              <p>Product Manager, edSpring</p>
              <span>TIES</span>
            </div>
          </div>
          <div className="testimonial-holder">
            <p>
              Mr. Gandecki and his team collaborate very well with our local
              team coordinating efforts and{' '}
              <strong>
                leading our work on code quality and automated testing.
              </strong>{' '}
              They work easily from our feature specifications and always
              communicate quickly and professionally. I especially admire their
              skills in translating between technical matters and the end-user
              experience, and their ability to develop{' '}
              <strong>quality code that drives customer delight.</strong> They
              are able to work quite independently and reliably deliver their
              completed work on schedule. Their commitment to ongoing
              professional development and mentoring as a team benefits our
              project as they bring us best-practice and state-of-the-art
              solutions to our development needs.
            </p>
          </div>
        </div>
        <div className="item">
          <div className="d-flex justify-content-start align-items-center">
            <div className="testimonial-image">
              <img src={sam} alt="Sam Hatoum" />
            </div>
            <div className="dark-holder">
              <label className="section-title white">Sam Hatoum</label>
              <p>Founder</p>
              <span>Xolv.io</span>
            </div>
          </div>
          <div className="testimonial-holder">
            <p>
              <strong>
                I was worried at first about working with a remote company but
                TheBrain turned this worry into a pleasure.
              </strong>{' '}
              They were very responsive and went above and beyond their call of
              duty to assist especially when we had some urgent issues to
              resolve.
            </p>
            <p>
              These guys are very skilled and knowledgeable especially when it
              comes to <strong>Test Driven Development</strong> which is our #1
              priority at <a href="#">Xolv.io</a>. The code quality they produce
              is of a very high standard and I’m glad to have these guys
              delivering our products and providing support.
            </p>
          </div>
        </div>
        <div className="item">
          <div className="d-flex justify-content-start align-items-center">
            <div className="testimonial-image">
              <img src={tim} alt="Tim Heckel" />
            </div>
            <div className="dark-holder">
              <label className="section-title white">Tim Heckel</label>
              <p>Founder/Architect</p>
              <span>CedarLabs</span>
            </div>
          </div>
          <div className="testimonial-holder">
            <p>
              TheBrain Software House is a unique mix of raw talent and
              innovation: by combining a rigor for learning, constant
              self-evaluation and improvement techniques, and a passion for
              first-class testing, this crew consistently{' '}
              <strong>produces rock-solid software at breakneck speed.</strong>{' '}
              It's truly phenomenal what they've been able to produce, time and
              again, even on shoestring budgets.
            </p>
            <p>
              I've known Lukasz Gandecki, TheBrain's founder, for 4+ years. He's
              an exceptionally talented developer and leader with a commitment
              to deliver on time and under budget. His team is perfectly
              tailored to <strong>save you loads of money and time.</strong>{' '}
              They will consistently deliver testable, maintainable codebases
              that will make you smile.{' '}
              <strong>Give them your ideas and watch them flourish.</strong>
            </p>
          </div>
        </div>

        <div className="item">
          <div className="d-flex justify-content-start align-items-center">
            <div className="testimonial-image">
              <img src={jerome} alt="Jerome Figueroa" />
            </div>
            <div className="dark-holder">
              <label className="section-title white">Jerome Figueroa</label>
              <p>Software Development Manager</p>
              <span>TIES</span>
            </div>
          </div>
          <div className="testimonial-holder">
            <p>
              <strong>Lukasz and team</strong> is a great asset to have. On the
              technical aspect you see a{' '}
              <strong>
                high level of code output -- quality, artifacts and solutions
              </strong>
              . Their work ranged from new features development, performance
              improvement/tuning and fixes/support. Worth mentioning is the
              great work they did on testing (load testing, end to end testing –
              designing, setting up the infrastructure/environment, coding,
              implementing) – all integrated into the development process with
              the whole team.
            </p>
            <p>
              They have the ability to use the necessary tools and processes
              which translates to higher velocity.{' '}
              <strong>Collaborative and very easy to communicate with</strong> –
              this means a lot especially considering they are a remote team.
            </p>
            <p>I definitely recommend them for development work.</p>
          </div>
        </div>

        <div className="item">
          <div className="d-flex justify-content-start align-items-center">
            <div className="testimonial-image">
              <img src={tomasz} alt="Tomasz Olejnik" />
            </div>
            <div className="dark-holder">
              <label className="section-title white">Tomasz Olejnik</label>
              <p>Project Manager</p>
              <span>European Investment Bank</span>
            </div>
          </div>
          <div className="testimonial-holder">
            <p>
              During our work with TheBrain Software House the team proved
              itself to be amazingly versatile and ingenious. Throughout the
              whole process they kept in mind usefulness of the solution and
              took care of the best user experience possible, what's more - each
              part of the project was{' '}
              <strong>
                delivered on time and meeting all of the requirements.
              </strong>{' '}
              It's truly phenomenal what they've been able to produce, time and
              again, even on shoestring budgets.
            </p>
            <p>
              Even though it was almost{' '}
              <strong>entirely remote cooperation</strong>, there were literally
              no communication problems - developers are very responsive and
              always keep track of what is happening. Our work required also a
              presentation of the result to the whole department, which was also
              conducted to everyone's satisfaction.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default Testimonials
